import {Pressable, Text, View, StyleSheet, PixelRatio} from "react-native";
import {useEffect, useState} from "react";

function MainButton({text, action, type = ButtonType.default}){
    const [isHover, setIsHover] = useState(false)
    const [bgColor, setBgColor] = useState({backgroundColor: 'skyblue'})
    useEffect(() => {
        switch (type) {
            case ButtonType.default:
                setBgColor(styles.buttonDefault)
                break
            case ButtonType.alert:
                setBgColor(styles.buttonAlert)
                break
            case ButtonType.other:
                setBgColor(styles.buttonOther)
                break
            case ButtonType.cancel:
                setBgColor(styles.buttonCancel)
                break
        }
    }, [type]);

    return (
        <View style={{flex: 1}}>
            <Pressable
                style={[styles.buttonWrapper,bgColor,isHover&&styles.buttonHover]}
                onPress={action}
                onHoverIn={()=>type===ButtonType.default&&setIsHover(true)}
                onHoverOut={()=>setIsHover(false)}
            >
                <Text
                    style={[styles.buttonText, type===ButtonType.cancel&&styles.buttonTextCancel]}>
                    {text}
                </Text>
            </Pressable>
        </View>
    )
}

const styles = StyleSheet.create({
    buttonWrapper: {
        width: '100%',
        height: PixelRatio.getPixelSizeForLayoutSize(48),//60 확인 전체검색 해서 찾을것
        borderRadius: PixelRatio.getPixelSizeForLayoutSize(10),
        alignItems: 'center',
        justifyContent: 'center',
    },
    buttonHover: {
        backgroundColor: '#2c592c',
    },
    buttonDefault: {
        backgroundColor: '#469046',
    },
    buttonOther: {
        backgroundColor: '#666565',
    },
    buttonAlert: {
        backgroundColor: '#DD7451',
    },
    buttonCancel: {
        backgroundColor: '#EDEDED',
    },
    buttonText: {
        color: '#FEFAED',
        lineHeight: 24,
        fontSize: PixelRatio.getPixelSizeForLayoutSize(18),
        fontWeight: '700',
    },
    buttonTextCancel: {
        color: '#666565'
    }
})


export enum ButtonType {
    default ,
    other,
    alert,
    cancel
}

export default MainButton