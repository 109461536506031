import {PixelRatio, Pressable, StyleSheet, Text, View} from "react-native";
import TopMenu from "../components/public/TopMenu.tsx";
import BottomView from "../components/public/BottomView.tsx";
import InputLine from "../components/acount/InputLine.tsx";
import {useEffect, useState} from "react";
import MainButton, {ButtonType} from "../components/public/MainButton.tsx";
import {client} from "../api/client.ts";
import {useMutation} from "@tanstack/react-query";
import {requests, responses, responseWrapper} from "../api/Entity.ts";
import {urls} from "../api/Url.ts";

function Login_FindId({navigation, route}){

    const [name, setName] = useState('')
    const [tel, setTel] = useState('')
    const [id, setId] = useState('')

    const [code, setCode] = useState('')

    const [checkData, setCheckData] = useState(false)
    const [sendCode, setSendCode] = useState(false)
    const [successSendCode, setSuccessSendCode] = useState(false)
    const [successFindId, setSuccessFindId] = useState(false)

    useEffect(() => {
        if (name.length > 0 && tel.length === 11) {
            setCheckData(true)
        }else {
            setCheckData(false)
        }
    }, [name, tel]);


    const sendCodeInstance = useMutation({
        mutationFn: (req: requests['sendCode'])=> client.post<responseWrapper<responses['sendCode']>>(urls.sendCode, req),
        onSuccess: ({data})=>{
            if(data.status) {
                setSendCode(true)
            }else {
                setSendCode(false)
                alert('정보가 정확하지 않습니다.')
            }

        }
    })

    const confirmCodeInstance = useMutation({
        mutationFn: (req: requests['confirmCode'])=> client.post<responseWrapper<boolean>>(urls.confirmCode, req),
        onSuccess: ({data})=>{
            if(data.data) {
                const req = {
                    name: name,
                    tel: tel
                }
                findIdInstance.mutate(req)
            }else {
                alert('인증번호가 정확하지 않습니다.')
            }
        }
    })

    const findIdInstance = useMutation({
        mutationFn: (req: requests['findId'])=> client.post<responseWrapper<responses['findId']>>(urls.findId, req),
        onSuccess: ({data})=> {
            if(data.status) {
                setId(data.data.id)
                setSuccessFindId(true)
            }else {
                alert(data.errorcode)
            }
        },
        onError: (error)=> alert(error)
    })

    return (
        <View style={styles.wrapper}>
            <TopMenu navigation={navigation} route={route} userVisible={false}/>
            <View style={styles.contentWrapper}>

                <Text style={styles.title}>[아이디 찾기]</Text>

                <View style={{gap: PixelRatio.getPixelSizeForLayoutSize(28)}}>
                    {successFindId === false ? (
                        <>
                            <InputLine subject={'이름'} text={name} setText={setName} addStar={true}
                                       hint={'이름을 입력하세요.'}/>
                            <InputLine subject={'연락처'} text={tel} setText={setTel} addStar={true} isPhone={true}/>

                            <MainButton text={'인증번호 받기'} type={checkData?ButtonType.default : ButtonType.cancel}
                                        action={()=>{
                                            if (checkData) {
                                                const req: requests['sendCode'] = {
                                                    id: undefined,
                                                    name: name,
                                                    tel: tel
                                                }
                                                sendCodeInstance.mutate(req)
                                            }
                                        }}
                            />
                            <View style={styles.codeWrapper}>
                                <Text style={styles.codeTitle}>인증번호</Text>
                                <View style={{flex: 1}}>
                                    <InputLine text={code} setText={setCode} addStar={false} useSubject={false} subject={''}
                                               hint={'6자리 숫자를 입력해주세요.'}/>
                                </View>
                            </View>

                            <MainButton text={'확인'} type={sendCode?ButtonType.default : ButtonType.cancel}
                                        action={()=>{
                                            if (sendCode) {
                                                const req = {
                                                    code: code,
                                                    tel: tel
                                                }
                                                confirmCodeInstance.mutate(req)
                                            }
                                        }}
                            />
                        </>
                ) : (
                        <>
                            <View style={styles.successWrapper}>
                                <Text style={styles.successText}>{name} 님의 아이디는</Text>
                                <Text style={styles.successText}><Text style={styles.successIdText}>{id}</Text> 입니다.</Text>
                            </View>
                            <View style={{flexDirection: 'row', justifyContent: 'center'}}>
                                <Pressable onPress={()=>navigation.push('changePassword')}>
                                    <Text style={[styles.successBottomText, {textDecorationLine: 'underline'}]}>
                                        비밀번호 재설정
                                    </Text>
                                </Pressable>
                                <Text style={styles.successBottomText}> / </Text>
                                <Pressable onPress={()=>navigation.push('login')}>
                                    <Text style={[styles.successBottomText,{textDecorationLine: 'underline'}]}>
                                        로그인하기
                                    </Text>
                                </Pressable>

                            </View>
                        </>
                    )}
                </View>

            </View>


            <BottomView/>
        </View>
    )
}

const styles = StyleSheet.create({
    wrapper: {
        width: '100vw',
        minHeight: innerHeight,
        backgroundColor: '#FEFAED',
    },
    contentWrapper: {
        padding: PixelRatio.getPixelSizeForLayoutSize(28),
        gap: PixelRatio.getPixelSizeForLayoutSize(28),
        flex: 1,
    },
    title: {
        color: '#19943B',
        fontWeight: '800',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(20),
        lineHeight: PixelRatio.getPixelSizeForLayoutSize(25),
        textAlign: 'center'
    },
    codeWrapper: {
        flexDirection: 'row',
        flex: 1,
        alignItems: 'center',
        gap: PixelRatio.getPixelSizeForLayoutSize(28)
    },
    codeTitle: {
        color: '#666565',
        fontWeight: '800',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(14),
        lineHeight: PixelRatio.getPixelSizeForLayoutSize(15.89),
        textAlign: 'center'
    },
    successWrapper: {
        paddingVertical: PixelRatio.getPixelSizeForLayoutSize(38),
        alignItems: 'center',
        borderColor: '#b2b2b2',
        borderRadius: PixelRatio.getPixelSizeForLayoutSize(10),
        borderWidth: PixelRatio.getPixelSizeForLayoutSize(1)
    },
    successText: {
        color: '#666565',
        fontWeight: '800',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(18),
        lineHeight: PixelRatio.getPixelSizeForLayoutSize(25.2),
        textAlign: 'center'
    },
    successIdText: {
        color: '#DD7451'
    },
    successBottomText: {
        color: '#666565',
        fontWeight: '400',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(12),
        lineHeight: PixelRatio.getPixelSizeForLayoutSize(19.2),
        textAlign: 'center',
    }
})

export default Login_FindId