import {PixelRatio, Pressable, StyleSheet, Text, View} from "react-native";
import TopMenu from "../components/public/TopMenu.tsx";
import InputLine from "../components/acount/InputLine.tsx";
import MainButton, {ButtonType} from "../components/public/MainButton.tsx";
import {requests, responses, responseWrapper} from "../api/Entity.ts";
import BottomView from "../components/public/BottomView.tsx";
import {useEffect, useState} from "react";
import {useMutation} from "@tanstack/react-query";
import {client} from "../api/client.ts";
import {urls} from "../api/Url.ts";

enum state_ {
    GetData,
    ChangePassword,
    Success
}

function Login_ChangePassword({navigation, route}) {

    const [name, setName] = useState('')
    const [tel, setTel] = useState('')
    const [id, setId] = useState('')
    const [code, setCode] = useState('')

    const [checkData, setCheckData] = useState(false)
    const [sendCode, setSendCode] = useState(false)
    const [confirmCode, setConfirmCode] = useState(false)

    const [state, setState] = useState(state_.GetData)

    const [password1, setPassword1] = useState('')
    const [password2, setPassword2] = useState('')

    useEffect(() => {
        if(name.length > 0 && id.length > 0 && tel.length === 11) {
            setCheckData(true)
        }else {
             setCheckData(false)
        }
    }, [name, id, tel]);

    const [passwordCheck, setPasswordCheck] = useState(false)
    useEffect(() => {
        if (password1===password2 && password1.length > 0) {
            setPasswordCheck(true)
        }else {
            setPasswordCheck(false)
        }
    }, [password1, password2]);

    const sendCodeInstance = useMutation({
        mutationFn: (req: requests['sendCode'])=> client.post<responseWrapper<responses['sendCode']>>(urls.sendCode, req),
        onSuccess: ({data})=>{
            if(data.status) {
                setSendCode(true)
            }else {
                setSendCode(false)
                alert('정보가 정확하지 않습니다.')
            }

        }
    })

    const confirmCodeInstance = useMutation({
        mutationFn: (req: requests['confirmCode'])=> client.post<responseWrapper<boolean>>(urls.confirmCode, req),
        onSuccess: ({data})=>{
            if(data.status) {
                if(data.data) {
                    setConfirmCode(true)
                    setState(state_.ChangePassword)
                }else {
                    alert('인증번호가 정확하지 않습니다.')
                    setConfirmCode(false)
                }
            }
        }
    })

    const changePasswordInstance = useMutation({
        mutationFn: (req: requests['passwordChange'])=> client.post<responseWrapper<boolean>>(urls.passwordChange, req),
        onSuccess: ({data})=> {
            if (data.status) {
                if(data.errorcode === 0) {
                    setState(state_.Success)
                }else{
                    setState(state_.ChangePassword)
                }
            }
        }
    })

    return (
        <View style={styles.wrapper}>
            <TopMenu navigation={navigation} route={route} userVisible={false}/>
            <View style={styles.contentWrapper}>

                <Text style={styles.title}>[비밀번호 재설정]</Text>

                <View style={{gap: PixelRatio.getPixelSizeForLayoutSize(28)}}>
                    {state === state_.GetData &&(<>
                        <InputLine subject={'이름'} text={name} setText={setName} addStar={true}
                        hint={'이름을 입력하세요.'}/>
                        <InputLine subject={'아이디'} text={id} setText={setId} addStar={true}
                        hint={'아이디를 입력하세요.'}/>
                        <InputLine subject={'연락처'} text={tel} setText={setTel} addStar={true} isPhone={true}/>

                        <MainButton text={'인증번호 받기'} type={checkData?ButtonType.default : ButtonType.cancel}
                                    action={()=>{
                                        if (checkData) {
                                            const req = {
                                                id: id,
                                                name: name,
                                                tel: tel
                                            }

                                            sendCodeInstance.mutate(req)

                                        }
                                    }}
                        />
                        <View style={styles.codeWrapper}>
                            <Text style={styles.codeTitle}>인증번호</Text>
                            <View style={{flex: 1}}>
                                <InputLine text={code} setText={setCode} addStar={false} useSubject={false} subject={''}
                                           hint={'6자리 숫자를 입력해주세요.'}/>
                            </View>
                        </View>

                        <MainButton text={'확인'} type={sendCode?ButtonType.default : ButtonType.cancel}
                                    action={()=>{
                                        if (sendCode) {
                                            const req = {
                                                tel: tel,
                                                code: code
                                            }

                                            confirmCodeInstance.mutate(req)
                                        }
                                    }}
                        />
                    </>)}
                    {state === state_.ChangePassword && (<>
                        <View>
                            <InputLine subject={'이름'} text={password1} setText={setPassword1} addStar={true} useSubject={false}
                            hint={'새로운 비밀번호'} isSecurity={true}/>
                            <InputLine subject={'아이디'} text={password2} setText={setPassword2} addStar={true} useSubject={false}
                            hint={'비밀번호 확인'} isSecurity={true}/>
                            {password1.length>0 &&
                                <Text style={[styles.hintText, password1!==password2 && styles.hintFailedColor]}>
                                    {password1===password2 ? '비밀번호가 일치합니다.' : '비밀번호를 다시 확인해주세요.'}
                                </Text>
                            }
                        </View>


                        <MainButton text={'변경하기'} type={passwordCheck?ButtonType.default : ButtonType.cancel}
                                    action={()=>{
                                        if (passwordCheck) {
                                            const req: requests['passwordChange'] = {
                                                id: id,
                                                newPassword: password2,
                                                isConfirmed: true
                                            }

                                            changePasswordInstance.mutate(req)
                                        }
                                    }}
                        />
                    </>)}
                    {state === state_.Success && (
                        <>
                            <View style={styles.successWrapper}>
                                <Text style={styles.successText}>{'비밀번호 변경이 완료되었습니다.\n변경된 정보로 로그인해주세요!'}</Text>

                            </View>
                            <View style={{flexDirection: 'row', justifyContent: 'center'}}>
                                <Pressable onPress={()=>navigation.push('login')}>
                                    <Text style={styles.successBottomText}>로그인하기</Text>
                                </Pressable>

                            </View>
                        </>
                    )}
                </View>

            </View>


            <BottomView/>
        </View>
    )
}

const styles = StyleSheet.create({
    wrapper: {
        width: '100vw',
        minHeight: innerHeight,
        backgroundColor: '#FEFAED',
    },
    contentWrapper: {
        padding: PixelRatio.getPixelSizeForLayoutSize(28),
        gap: PixelRatio.getPixelSizeForLayoutSize(28),
        flex: 1,
    },
    title: {
        color: '#19943B',
        fontWeight: '800',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(20),
        lineHeight: PixelRatio.getPixelSizeForLayoutSize(25),
        textAlign: 'center'
    },
    codeWrapper: {
        flexDirection: 'row',
        flex: 1,
        alignItems: 'center',
        gap: PixelRatio.getPixelSizeForLayoutSize(28)
    },
    codeTitle: {
        color: '#666565',
        fontWeight: '800',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(14),
        lineHeight: PixelRatio.getPixelSizeForLayoutSize(15.89),
        textAlign: 'center'
    },
    hintText: {
        color: '#1F7FB8',
        fontWeight: '400',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(12),
        lineHeight: PixelRatio.getPixelSizeForLayoutSize(19.2),
    },
    hintFailedColor: {
        color: '#DD7451'
    },
    successWrapper: {
        paddingVertical: PixelRatio.getPixelSizeForLayoutSize(38),
        alignItems: 'center',
        borderColor: '#b2b2b2',
        borderRadius: PixelRatio.getPixelSizeForLayoutSize(10),
        borderWidth: PixelRatio.getPixelSizeForLayoutSize(1)
    },
    successText: {
        color: '#666565',
        fontWeight: '800',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(18),
        lineHeight: PixelRatio.getPixelSizeForLayoutSize(25.2),
        textAlign: 'center'
    },
    successBottomText: {
        color: '#666565',
        fontWeight: '400',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(12),
        lineHeight: PixelRatio.getPixelSizeForLayoutSize(19.2),
        textAlign: 'center',
        textDecorationLine: 'underline'
    }
})

export default Login_ChangePassword