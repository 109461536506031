import axios from "axios";
import {urls} from "./Url.ts";
import {responses, responseWrapper} from "./Entity";
import AsyncStorage from "@react-native-async-storage/async-storage";

export const client = axios.create({
    //baseURL: 'https://server.eco-plogging.com/',
    baseURL: 'https://hs.ploggingtest.p-e.kr/' // 2
})

client.interceptors.response.use((config)=>{
    const code = config.data.errorcode
    if(code===998){
        addToken(refreshToken)
        client.post<responseWrapper<responses['login']>>(urls.refresh).then(({data})=>{
            if (data.status) {
                addToken(data.data.token)
                addRefreshToken(data.data.refreshToken)
                AsyncStorage.setItem('token', data.data.token).then();
                AsyncStorage.setItem('refreshToken', data.data.refreshToken).then();
            } else {
                removeToken()
                addRefreshToken(undefined)
                AsyncStorage.removeItem('token').then()
                AsyncStorage.removeItem('refreshToken').then()
            }

        })
    }else if (code === 997) {
        removeToken()
        addRefreshToken(undefined)
        AsyncStorage.removeItem('token').then()
        AsyncStorage.removeItem('refreshToken').then()
    }

    return config
}, ()=>{
    removeToken()
    addRefreshToken(undefined)
    AsyncStorage.removeItem('token').then()
    AsyncStorage.removeItem('refreshToken').then()
})

export function addToken(token){
    client.defaults.headers.common['Authorization'] = 'Bearer ' + token;
}
export function removeToken(){
    client.defaults.headers.common['Authorization'] = undefined;
}

let refreshToken = undefined;

export function addRefreshToken(refToken) {
    refreshToken = refToken;
}